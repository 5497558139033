// src/ImageSwapper.js
import React, { useState, useEffect } from 'react';

const ImageSwapper = ({ imageArray, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex + 1 === imageArray.length ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [imageArray, interval]);

  // Assuming imageArray contains URLs of images
  const currentImage = imageArray[currentIndex];

  return <img src={currentImage} alt="" class="image" loading="lazy" />;
};

export default ImageSwapper;
