// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <ul className="links">
        <li className="link">Sara Hemming Studio</li>
        <li className="link"><a href="https://www.instagram.com/sarahemmingstudio/" target="_blank" rel="noreferrer noopen">Instagram</a></li>
        <li className="link"><a href="mailto:sasha@streeters.com" target="_blank" rel="noreferrer noopen">Agent</a></li>
        <li className="link"><a href="mailto:studio@sarahemming.com" target="_blank" rel="noreferrer noopen">Contact</a></li>
      </ul>
    </footer>
  );
};

export default Footer;
