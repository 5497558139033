import React, { useEffect } from 'react';
import ImageSwapper from './ImageSwapper';
import Header from './Header';
import Footer from './Footer';
import './App.css';
import images from './images.json';
const App = () => {
  // This effect runs once when the component mounts
  useEffect(() => {
    // This function sets a CSS variable with the actual viewport height
    const setViewportHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set the viewport height after the component loads
    setViewportHeight();

    // Update the viewport height whenever the window is resized
    window.addEventListener('resize', setViewportHeight);

    // Cleanup: remove the event listener when the component is unmounted
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []); // The empty array means this useEffect runs once, equivalent to componentDidMount

  return (
    <div className="App">
      <Header />
      <div className="image-container">
        <ImageSwapper imageArray={images} interval={1700} />
      </div>
      <Footer/>
    </div>
  );
}

export default App;